import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from './components/Navbar'
import HeaderBar from './components/HeaderBar'
import PrivateRoute from './components/PrivateRoute'
import Home from './pages/Home'
import About from './pages/About'
import ContactUs from './pages/ContactUs'
import Services from './pages/Services'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import ForgotPassword from './pages/ForgotPassword'
import Analytics from './pages/Analytics'
import AccountPage from './pages/AccountPage'
import { GlobalContextProvider } from './contexts/GlobalContext'
import './App.css'

function App() {
  //private route to Analytics
  //  uses the Outlet (Analytics passed in ad propt) in the PrivateRoute component
  return (
    <>
      <GlobalContextProvider>
        <Router>
          <HeaderBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/analytics" element={<PrivateRoute />}>
              <Route path="/analytics" element={<Analytics />} />
            </Route>
            <Route path="account" element={<PrivateRoute />}>
              <Route path="/account" element={<AccountPage />} />
            </Route>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/" element={<SignUp />} />
          </Routes>
          <Navbar />
        </Router>
      </GlobalContextProvider>
      <ToastContainer />
    </>
  )
}

export default App

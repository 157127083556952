//rfce - react functional component with export
import { useState, useEffect } from 'react'

//import plot from '../../src/indicators.html'

function Analytics() {
  /*
  Radio Buttons; analyticsService
  - Example:https://dev.to/collegewap/how-to-work-with-radio-buttons-in-react-3e0o
  - defaults to empty string
  */

  const [analyticsService, setAnalyticsService] = useState('')

  const onAnayticsChange = (e) => {
    setAnalyticsService(e.target.value)
  }

  /*
  html state variable forecast services: dataLoad, dataTransform, dataForecast
  */

  const [dataLoad, setDataLoad] = useState({
    name: '',
    date: '',
    environment: '',
    port: '',
    html: '',
  })

  const [dataTransform, setDataTransform] = useState({
    name: '',
    date: '',
    environment: '',
    port: '',
    html: '',
  })

  const [dataForecast, setdataForecast] = useState({
    name: '',
    date: '',
    environment: '',
    port: '',
    html: '',
  })

  /* get html figure
  //https://stackoverflow.com/questions/67851729/render-html-div-in-react
  */

  const loadData = () => {
    const body = JSON.stringify({ get_data: 'load' })
    console.log('load data')
    fetch('/api/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    }).then((res) =>
      res.json().then((data) => {
        // Setting a data from api
        //console.log(data)
        setDataLoad({
          name: data.Name,
          date: data.Date,
          environment: data.Environment,
          port: data.Port,
          html: data.html,
        })
      }),
    )
    //console.log(data)
  }

  const transformData = () => {
    console.log('transform data')

    const body = JSON.stringify({ get_data: 'transform' })
    //console.log('hello')
    fetch('/api/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    }).then((res) =>
      res.json().then((data) => {
        // Setting a data from api
        //console.log(data)
        setDataTransform({
          name: data.Name,
          date: data.Date,
          environment: data.Environment,
          port: data.Port,
          html: data.html,
        })
      }),
    )
    //console.log(data)
  }

  const getForecast = () => {
    console.log('forecast')

    const body = JSON.stringify({ get_data: 'forecast' })
    //console.log('hello')
    fetch('/api/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    }).then((res) =>
      res.json().then((data) => {
        // Setting a data from api
        //console.log(data)
        setdataForecast({
          name: data.Name,
          date: data.Date,
          environment: data.Environment,
          port: data.Port,
          html: data.html,
        })
      }),
    )
    //console.log(data)
  }

  const [load, setLoad] = useState({ click: false, loaded: false })
  const [transform, setTransform] = useState({
    click: false,
    transformed: false,
  })
  const [forecast, setForecast] = useState({ click: false, forecasted: false })

  const loadClick = () => setLoad({ click: true, loaded: false })

  const transformClick = () => setTransform({ click: true, transformed: false })

  const forecastClick = () => setForecast({ click: true, forecasted: false })

  useEffect(() => {
    console.log('mount')

    if (load.click === true) {
      console.log('load = ', load)
      loadData()
      setLoad({ click: false, loaded: true })
    }

    if (transform.click === true) {
      console.log('transform = ', transform)
      transformData()
      setTransform({ click: false, transformed: true })
    }

    if (forecast.click === true) {
      console.log('forecast = ', forecast)
      getForecast()
      setForecast({ click: false, forecasted: true })
    }
  }, [load, transform, forecast])

  return (
    <>
      {/**********LEFT PANE****************/}
      <div className="splitLeftContainer">
        <h3>Service</h3>
        <input
          type="radio"
          name="analyticsService"
          value="salesAnalytics"
          id="salesAnalytics"
          checked={analyticsService === 'salesAnalytics'}
          onChange={onAnayticsChange}
        />
        <label htmlFor="salesAnalytics">Sales Analytics</label>
        <br />
        <input
          type="radio"
          name="analyticsService"
          value="forecasting"
          id="forecasting"
          checked={analyticsService === 'forecasting'}
          onChange={onAnayticsChange}
        />
        <label htmlFor="forecasting">Forecasting</label>
        <br />
        <input
          type="radio"
          name="analyticsService"
          value="segmentation"
          id="segmentation"
          checked={analyticsService === 'segmentation'}
          onChange={onAnayticsChange}
        />
        <label htmlFor="segmentation">Segmententation</label>
        <br />
        <input
          type="radio"
          name="analyticsService"
          value="churnAnalytics"
          id="churnAnalytics"
          checked={analyticsService === 'churnAnalytics'}
          onChange={onAnayticsChange}
        />
        <label htmlFor="churnAnalytics">Churn Analytics</label>
        <br />
        <input
          type="radio"
          name="analyticsService"
          value="pricingAnalytics"
          id="pricingAnalytics"
          checked={analyticsService === 'pricingAnalytics'}
          onChange={onAnayticsChange}
        />
        <label htmlFor="pricingAnalytics">Pricing Analytics</label>
      </div>
      {/**********RIGHT PANE****************/}
      <div className="splitWrapper">
        <div className="splitRightContainer">
          {analyticsService === 'salesAnalytics' ? (
            <>
              {/*---------- Sales Analytics ----------*/}
              <h3>Sales Analytics</h3>
            </>
          ) : analyticsService === 'forecasting' ? (
            <>
              {/*---------  Forecasting -------------*/}
              <h3>Forecasting</h3>

              <br />
              <br />
              <div>
                <button className="button" onClick={loadClick}>
                  load data
                </button>
                <br />
                <br />
                {load.loaded ? (
                  <iframe
                    title="html plot"
                    srcdoc={dataLoad.html[0]}
                    width="750px"
                    height="270px"
                    style={{ border: 'none' }}
                  ></iframe>
                ) : null}
                <br />
                <br />
                <button className="button" onClick={transformClick}>
                  transform data
                </button>
                <br />
                <br />
                {transform.transformed ? (
                  <iframe
                    title="html plot"
                    srcdoc={dataTransform.html[0]}
                    width="750px"
                    height="270px"
                    style={{ border: 'none' }}
                  ></iframe>
                ) : null}
                <br />
                <br />
                <button className="button" onClick={forecastClick}>
                  Forecast
                </button>
                <br />
                <br />
                {forecast.forecasted ? (
                  <>
                    <iframe
                      title="html plot"
                      srcdoc={dataForecast.html[2]}
                      width="750px"
                      height="100px"
                      style={{ border: 'none' }}
                    ></iframe>
                    <iframe
                      title="html plot"
                      srcdoc={dataForecast.html[0]}
                      width="750px"
                      height="200px"
                      style={{ border: 'none' }}
                    ></iframe>
                    <iframe
                      title="html plot"
                      srcdoc={dataForecast.html[1]}
                      width="750px"
                      height="500px"
                      style={{ border: 'none' }}
                    ></iframe>
                  </>
                ) : null}
                <br />
              </div>
            </>
          ) : analyticsService === 'segmentation' ? (
            <>
              <h3>Segmentation</h3>
            </>
          ) : analyticsService === 'churnAnalytics' ? (
            <>
              <h3>Churn Analytics</h3>
            </>
          ) : analyticsService === 'pricingAnalytics' ? (
            <>
              <h3>Pricing Analytics</h3>
            </>
          ) : (
            <>
              <p>Select Service</p>{' '}
            </>
          )}
        </div>{' '}
        {/*close split right container */}
      </div>{' '}
      {/*close split wrapper*/}
    </>
  )
}

export default Analytics

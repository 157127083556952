// react functional export component
import { useState } from 'react'
import { toast } from 'react-toastify'
import emailjs from '@emailjs/browser'

function ContactUs() {
  // conatct Us Form state
  const [contactUsFormData, setContactUsFormData] = useState({
    email: '',
    message: '',
  })

  // destructure fields
  const { email, message } = contactUsFormData

  //L84 use id property email or password
  const onChange = (e) => {
    setContactUsFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  const sendEmail = async (e) => {
    // prevents default action ... refressing the page
    e.preventDefault()

    if (email.length > 0 && message.length > 0) {
      try {
        emailjs.sendForm(
          process.env.REACT_APP_SEND_EMAIL_SERVICE_ID,
          process.env.REACT_APP_SEND_EMAIL_TEMPLATE_ID,
          e.target,
          process.env.REACT_APP_SEND_EMAIL_PUBLIC_KEY,
        )
        toast.success('Sending')
      } catch (error) {
        toast.error('Could not send')
      }
    } else {
      toast.error('fill in email and message fields ')
    }
  }

  return (
    <div className="contactUsContainer">
      <h1> Contact Us</h1>
      <form onSubmit={sendEmail}>
        <input
          type="email"
          className="emailInput"
          placeholder="Email"
          name="email_from"
          id="email"
          value={email}
          onChange={onChange}
        />
        <textarea
          type="text"
          className="messageInput"
          placeholder="Message"
          wrap="soft"
          name="message"
          id="message"
          value={message}
          onChange={onChange}
        />
        <button
          type="submit"
          style={{
            fontFamily: 'Montserrat, sans-serif',
            marginleft: '2rem',
            fontSize: '1rem',
            fontWeight: '600',
            backgroundColor: '#00cc66',
            borderRadius: '1rem',
            marginTop: '.5rem',
            color: '#ffffff',
          }}
        >
          <p> Send</p>
        </button>
      </form>
    </div>
  )
}

export default ContactUs

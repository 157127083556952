//rfce - react functional component with export
import { Link } from 'react-router-dom'

function About() {
  return (
    <>
      <div className="pageContainer">
        <h1>About</h1>
        <p>Democratizing AI Analytics for all businesses</p>
      </div>
      <div style={{ display: 'flex', padding: '1rem' }}>
        <button
          style={{
            fontFamily: 'Montserrat, sans-serif',
            marginRight: 'auto',
            marginLeft: '1rem',
            fontSize: '1rem',
            fontWeight: '600',
            backgroundColor: '#00cc66',
            borderRadius: '1rem',
            marginTop: '0rem',
            padding: '0.5rem',
          }}
        >
          <Link to="/contact-us" className="buttonText">
            Contact Us
          </Link>
        </button>
      </div>
    </>
  )
}

export default About

//rfce - react functional component with export
import { useState, useContext } from 'react'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import visibilityIcon from '../assets/svg/visibilityIcon.svg'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import OAuth from '../components/SocialOAuth'
import GlobalContext from '../contexts/GlobalContext'

// Sign In page created using L84 (Sign In) example
//  ... modified for useContext and AlgoBiz pages and routes
//  ... work on onSubmit once we have Firebase setup
function SignIn() {
  const [showPassword, setShowPassword] = useState(false)

  //L84 SignIn Form
  // ... create one piece of state userredentials - email, password
  // ... setUserCredentials
  const { email, password, setEmail, setPassword } = useContext(GlobalContext)

  // onChange
  // https://www.educative.io/answers/how-to-identify-different-inputs-with-one-onchange-handler
  // captures characters typed into form fields
  // - updates the form data
  // capture the prev state and adds new characters
  // e = event parameter
  // in JSX (below) id of email or id of password
  //  -  this is property we want to change
  //  - to do that we need to surround in brackets [e.target.id]
  const onChange = (e) => {
    switch (e.target.id) {
      case 'email':
        return setEmail(e.target.value)
      case 'password':
        return setPassword(e.target.value)
      default:
        return
    }
  }

  // if user authenticate successful then navigate to
  const navigate = useNavigate()
  //on Submit
  // this will be an async function
  //   since we will eventually call firebase auth
  // if authenticated navigate to home page
  const onSubmit = async (e) => {
    e.preventDefault()

    // set userCredential to true for temporarily untl we setup firebase authentication
    //const userCredential = await signInWithEmailAndPassword(auth, email,password)
    //const userCredential = { user: true }
    try {
      const auth = getAuth()
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      )

      if (userCredential.user) {
        //console.log(userCredential.user)

        // clear variables in case return to this page
        setEmail('')
        setPassword('')

        // navigate to phome
        navigate('/analytics')
      }
    } catch (error) {
      //console.log(error)
      toast.error('Bad User Credentials')
    }
  }

  return (
    <div className="signInContainer">
      {/*Sign In Form */}
      <div>
        <form onSubmit={onSubmit}>
          <input
            type="email"
            className="emailInput"
            placeholder="Email"
            id="email"
            value={email}
            onChange={onChange}
          />
          <div className="passwordInputDiv">
            <input
              type={showPassword ? 'text' : 'password'}
              className="passwordInput"
              placeholder="Password"
              id="password"
              value={password}
              onChange={onChange}
            />
            {/* function takes in previous state 
              toggle the visibility
              ... previous state */}
            <img
              src={visibilityIcon}
              alt="show password"
              className="showPassword"
              onClick={() => setShowPassword((prevState) => !prevState)}
            />
          </div>
          {/*Sign In Button... submit form */}
          <button className="button">Sign In</button>
        </form>
      </div>
      {/*forgot password ... */}
      <div style={{ display: 'flex', padding: '1rem' }}>
        <button className="buttonRight">
          <Link to="/forgot-password" className="buttonText">
            Forgot Password
          </Link>
        </button>
      </div>
      <OAuth />
    </div>
  )
}

export default SignIn

import { globalActionTypes as actions } from './GlobalActions'

// reducer function to handle state transactions
// returns updated state variables
// pass in the reducer function ("globalReducer") to useReducer when want to update state
// useReducer() replaces useState()
const globalReducer = (state, action) => {
  switch (action.type) {
    case actions.SET_NAME:
      return { ...state, name: action.payload }
    case actions.SET_EMAIL:
      return { ...state, email: action.payload }
    case actions.SET_PASSWORD:
      return { ...state, password: action.payload }
    case actions.LOGIN:
      return { ...state, isLoggedIn: true }
    case actions.LOGOUT:
      return { ...state, isLoggedIn: false }
    default:
      return state
  }
}

export default globalReducer

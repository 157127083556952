//rfce - react functional component with export
import { useState } from 'react'
import { getAuth, updateProfile } from 'firebase/auth'
import { db } from '../firebase.config'
import { doc, updateDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'

function PersonalHome() {
  const auth = getAuth()

  // we will have a button to change the details
  const [changeDetails, setChangeDetails] = useState(false)
  const [formData, setFormData] = useState({
    name: auth.currentUser.displayName,
    email: auth.currentUser.email,
  })

  // destructure name and email from formData
  const { name, email } = formData

  const onSubmit = async () => {
    try {
      // if sure display name != firebase name then update
      if (auth.currentUser.displayName !== name) {
        // Update auth firestore display name
        await updateProfile(auth.currentUser, {
          displayName: name,
        })
        // Update in firestore
        //  to update document need to create a ref to the document
        //  takes in db from config file, collection users, id from auth.currentUser
        //  recall that user id in the firestore is the same as id in the authentication
        //  note, use shorthand "name" ... same as "name:name"
        //  note ... cannot change email through display name so we do not try to do that here
        //       ... emails is used as login ... cannot change it due to authentication ... gets tricky
        const userRef = doc(db, 'users', auth.currentUser.uid)
        await updateDoc(userRef, {
          name,
        })
      }
      // use toast error
    } catch (error) {
      console.log(error)
      toast.error('Could not update profile details')
    }
  }

  //L90 Change User Details
  // pass in a function to get the preious state
  //   takes in prev state and returns an object (prevState + new typed character)
  //    prevState, get it by the key e.target.id and set to the value
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  // email change disabled (no onChange specified)
  return (
    <div className="profile">
      <main>
        <div className="profileDetailsHeader">
          <p className="profileDetailsText">Perssonal Details</p>
          <p
            className="changePersonalDetails"
            onClick={() => {
              changeDetails && onSubmit()
              setChangeDetails((prevState) => !prevState)
            }}
          >
            {changeDetails ? 'done' : 'change'}
          </p>
        </div>
        <div className="profileCard">
          <form>
            <input
              type="text"
              id="name"
              className={!changeDetails ? 'profileName' : 'profileNameActive'}
              disabled={!changeDetails}
              value={name}
              onChange={onChange}
            />
            <input
              type="text"
              id="email"
              className={!changeDetails ? 'profileEmail' : 'profileEmailActive'}
              disabled={!changeDetails}
              value={email}
            />
          </form>
        </div>
      </main>
    </div>
  )
}

export default PersonalHome

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDbnci6i0RI-Mbqpp9N8RTzV8-8j_Lgmy8',
  authDomain: 'algobizai.firebaseapp.com',
  projectId: 'algobizai',
  storageBucket: 'algobizai.appspot.com',
  messagingSenderId: '639902011252',
  appId: '1:639902011252:web:3dc3b94d9eff1a6538e621',
}

// Initialize Firebase
// eslint-disable-next-line
const app = initializeApp(firebaseConfig)
export const db = getFirestore()

import { createContext, useReducer } from 'react'
import globalReducer from './GlobalReducer'
import { globalActionTypes as actions } from './GlobalActions'

// initiali global state
const initialGlobalState = {
  name: '',
  email: '',
  password: '',
}

// Global Context
const GlobalContext = createContext()

// Provider - function that Provids the context (i.e., state)
// takes an argument called children
// reducer returns state and dispatch
// Dispatch is a function which dispatches an action with type and payload.
//  dispatch type ... type or specific state to be updated
//  dispatch payload  ... data needed for state update
export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialGlobalState)

  // value contains state, and dispatch actions with type and payload definitions
  // actions are available directly to the components

  //const { userreds: email, userCreds: password, isLoggedIn } = state
  const { name, email, password } = state

  const setName = (payload) => {
    dispatch({
      type: actions.SET_NAME,
      payload,
    })
  }
  const setEmail = (payload) => {
    dispatch({
      type: actions.SET_EMAIL,
      payload,
    })
  }
  const setPassword = (payload) => {
    dispatch({
      type: actions.SET_PASSWORD,
      payload,
    })
  }
  return (
    <GlobalContext.Provider
      value={{
        name,
        email,
        password,
        setName,
        setEmail,
        setPassword,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext

// rfce - react functional compoenent with export
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as HomeIcon } from '../assets/svg/homeIcon.svg'
import { ReactComponent as PeopleIcon } from '../assets/svg/people-outline.svg'
import { ReactComponent as GraphIcon } from '../assets/svg/graph.svg'

/*
- color of the SVG Icon will be different if on the page
- initialize navigate
- location
- pathMatchRoute

*/
/* Navbar
 - Home Page ... HomeIcon
 - Services ... GraphIcon
 - About ... PeopleIcon
  */

function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const pathMatchRoute = (route) => {
    if (route === location.pathname) return true
  }

  return (
    <footer className="navbar">
      <nav className="navbarNav">
        <ul className="navbarListItems">
          <li className="navbarListItem" onClick={() => navigate('/')}>
            <HomeIcon
              fill={pathMatchRoute('/') ? '#2c2c2c' : '#8f8f8f'}
              width="36px"
              height="36px"
            />
            <p
              className={
                pathMatchRoute('/')
                  ? 'navbarListItemNameActive'
                  : 'navBarListItemName'
              }
            >
              Home
            </p>
          </li>
          <li className="navbarListItem" onClick={() => navigate('/services')}>
            <GraphIcon
              fill={pathMatchRoute('/services') ? '#2c2c2c' : '#8f8f8f'}
              width="36px"
              height="36px"
            />
            <p
              className={
                pathMatchRoute('/services')
                  ? 'navbarListItemNameActive'
                  : 'navBarListItemName'
              }
            >
              Services
            </p>
          </li>
          <li className="navbarListItem" onClick={() => navigate('/about')}>
            <PeopleIcon
              fill={pathMatchRoute('/about') ? '#2c2c2c' : '#8f8f8f'}
              width="36px"
              height="36px"
            />
            <p
              className={
                pathMatchRoute('/about')
                  ? 'navbarListItemNameActive'
                  : 'navBarListItemName'
              }
            >
              About Us
            </p>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Navbar

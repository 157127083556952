//rfce - react functional component with export
import forecast from '../assets/images/forecast_250_254178033.jpg'
import pricing from '../assets/images/Pricing_250_353274510.jpg'
import segmentation from '../assets/images/segmentation_analytics_250_480881275.jpeg'

function Services() {
  return (
    <div className="servicesContainer">
      <img
        src={segmentation}
        alt="Segmentation"
        width="250px"
        height="150px"
      ></img>

      <img src={pricing} alt="Picing" width="250px" height="150px"></img>
      <img src={forecast} alt="forecast" width="250px" height="150px"></img>
      <h1>Services</h1>
      <ul>
        <li>Data upload or API interface</li>
        <li>AI assisted analysis</li>
        <li>Human language interaction</li>
        <li>Interactive dashboard visualization </li>
        <li>Forecasts adjusted for season and vayring conditions</li>
        <li>Quantified churn propensity and reasons for churn</li>
        <li>Optimized pricing adjusted for season and dynamic inputs</li>
        <li>Customer segmentaton based on demographics or RFM history</li>
        <li>Results download or API interface</li>
      </ul>
    </div>
  )
}

export default Services

//Outlet allows us to render child routes/child elements
import { Navigate, Outlet } from 'react-router-dom'
import { useAuthStatus } from '../hooks/useAuthStatus'
import Spinner from './Spinner'

const PrivateRoute = () => {
  // destructure useAuthStatus the two values it returns
  const { loggedIn, checkingStatus } = useAuthStatus()

  // return spinner but for now h3 loading
  if (checkingStatus) {
    return <Spinner />
  }

  // if not checking status then will return as below
  //   Outlet nested route if loggedIn else redirect to signin
  return loggedIn ? <Outlet /> : <Navigate to="/signin" />
}

export default PrivateRoute

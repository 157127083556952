//rfce - react functional component with export

function Home() {
  // usestate for setting a javascript

  return (
    <div className="homeContainer">
      <h1>AI Analytics for Business</h1>
      <p>as a Service</p>
    </div>
  )
}

export default Home

// rfce - react functional compoenent with export
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { getAuth } from 'firebase/auth'

/*
- color of the SVG Icon will be different if on the page
- initialize navigate
- location
- pathMatchRoute

*/
/* Navbar
 - Home Page ... HomeIcon
 - Services ... GraphIcon
 - About ... PeopleIcon
  */

function HeaderBar() {
  const location = useLocation()
  const pathMatchRoute = (route) => {
    if (route === location.pathname) return true
  }

  const auth = getAuth()

  const navigate = useNavigate()

  const onLogout = () => {
    auth.signOut()
    navigate('/')
  }

  /*
   -   first checks that page route is "/" home not one of
      -- signin, signuo, analytics, account ...
  */

  return (
    <div className="headerBarContainer">
      {!pathMatchRoute('/signin') &&
      !pathMatchRoute('/signup') &&
      !pathMatchRoute('/analytics') &&
      !pathMatchRoute('/account') ? (
        <div className="headerContents">
          {/* / route not signed in */}
          <p className="algoBizText"> AlgoBizAI</p>
          <button className="buttonRight1">
            <Link to="/signin" className="buttonText">
              Sign In
            </Link>
          </button>
          {/* sign up */}
          <p>&nbsp;</p>
          <button className="buttonRight2">
            <Link to="/signup" className="buttonText">
              Sign Up
            </Link>
          </button>
        </div>
      ) : (
        <>
          ({/*signin*/}
          {pathMatchRoute('/signin') ? (
            <div className="headerContents2">
              <p className="algoBizText"> AlgoBizAI</p>
              <button className="buttonRight">
                <Link to="/signup" className="buttonText">
                  Sign Up
                </Link>
              </button>
            </div>
          ) : (
            <>
              ({/*signup*/}
              {pathMatchRoute('/signup') ? (
                <div className="headerContents2">
                  <p className="algoBizText"> AlgoBizAI</p>
                  <button className="buttonRight">
                    <Link to="/signin" className="buttonText">
                      Sign In
                    </Link>
                  </button>
                </div>
              ) : (
                <>
                  {' '}
                  {/*analytics*/}
                  <div className="headerContents2">
                    <p className="algoBizText"> AlgoBizAI</p>
                    <button className="buttonRight1" onClick={onLogout}>
                      Logout
                    </button>
                    {/* Account */}
                    <p>&nbsp;</p>
                    <button className="buttonRight2">
                      <Link to="/account" className="buttonText">
                        Account
                      </Link>
                    </button>
                  </div>
                </>
              )}{' '}
              {/* analytics */}
            </>
          )}{' '}
          {/* sign out */})
        </>
      )}{' '}
      {/* sign in */}
    </div>
  ) /* / route */
}

export default HeaderBar

//rfce - react functional component with export
import { useState, useContext } from 'react'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import visibilityIcon from '../assets/svg/visibilityIcon.svg'
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth'
import OAuth from '../components/SocialOAuth'
import { db } from '../firebase.config'
import GlobalContext from '../contexts/GlobalContext'
import { setDoc, doc, serverTimestamp } from 'firebase/firestore'
//firebase auth
//  import gitAuth, createUser... updateProfile
//  import db from firebase config

// Sign In page created using L84 (Sign In) example
//  ... modified for useContext and AlgoBiz pages and routes
//  ... work on onSubmit once we have Firebase setup
function SignUp() {
  const [showPassword, setShowPassword] = useState(false)

  // replaced with useContext
  //const [formData, setFormData] = useState({
  //  email: '',
  //  password: '',
  //})

  //L84 SignIn Form
  // ... create one piece of state userredentials - email, password
  // ... setUserCredentials
  const { name, email, password, setName, setEmail, setPassword } =
    useContext(GlobalContext)

  // onChange
  // https://www.educative.io/answers/how-to-identify-different-inputs-with-one-onchange-handler
  // captures characters typed into form fields
  // - updates the form data
  // capture the prev state and adds new characthers
  // e = event parameter
  // in JSX (below) id of email or id of password
  //  -  this is property we want to change
  //  - to do that we need to surround in brackets [e.target.id]
  const onChange = (e) => {
    console.log(e)
    switch (e.target.id) {
      case 'name':
        return setName(e.target.value)
      case 'email':
        return setEmail(e.target.value)
      case 'password':
        return setPassword(e.target.value)
      default:
        return
    }
  }

  // if user authenticate successful then navigate to
  const navigate = useNavigate()
  //on Submit
  // this will be an async function
  //   since we will eventually call firebase auth
  // if authenticated navigate to home page
  const onSubmit = async (e) => {
    // prevent default behavior
    e.preventDefault()
    //console.log(formData)

    // set userCredential to true for temporarily untl we setup firebase authentication
    //const userCredential = await signInWithEmailAndPassword(auth, email,password)
    // call createUserEmailPassword returns promise
    //   put it in userCredential
    // use try catch since not using .then syntax

    try {
      // initialize auth
      const auth = getAuth()

      // register the user
      // createUserWithEmailAnd Password
      //   takes in email and password from the form
      //   returns userCredential
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      )
      // get user from the userCredential
      // need user for the database
      const user = userCredential.user

      // get current user from auth
      //  update firebase  profile
      // update the Firebase display name
      updateProfile(auth.currentUser, {
        displayName: name,
      })

      //console.log('user: ', user)
      if (userCredential.user) {
        // create an object formxData
        //   save to firestore
        const formData = { name: name, email: email }
        // set timestamp to serverTimestamp ... when it is submitted
        formData.timestamp = serverTimestamp()
        // setDoc to firestore, takes in doc(db,collection, user.id)
        //   saves document to the suers collection, setup previously in firestore
        //   document contents in our NoSQL  firestores will formData

        await setDoc(doc(db, 'users', user.uid), formData)

        // clear variables in case return to the page
        setName('')
        setEmail('')
        setPassword('')

        // navigate to phome
        navigate('/phome')
      }
    } catch (error) {
      toast.error('Registration Failed')
    }
  }

  return (
    <div className="signUpContainer">
      {/*Sign In Form */}
      <form onSubmit={onSubmit}>
        <input
          type="text"
          className="nameInput"
          placeholder="Name"
          id="name"
          value={name}
          onChange={onChange}
        />
        <input
          type="email"
          className="emailInput"
          placeholder="Email"
          id="email"
          value={email}
          onChange={onChange}
        />
        <div className="passwordInputDiv">
          <input
            type={showPassword ? 'text' : 'password'}
            className="passwordInput"
            placeholder="Password"
            id="password"
            value={password}
            onChange={onChange}
          />
          {/* function takes in previous state 
              toggle the visibility
              ... previous state */}
          <img
            src={visibilityIcon}
            alt="show password"
            className="showPassword"
            onClick={() => setShowPassword((prevState) => !prevState)}
          />
        </div>
        {/*Sign Up Button... submit form */}
        <button className="button">Sign Up</button>
      </form>

      {/*forgot password ... */}
      <div style={{ display: 'flex', padding: '1rem' }}>
        <button className="buttonRight">
          <Link to="/forgot-password" className="buttonText">
            Forgot Password
          </Link>
        </button>
      </div>
      <OAuth />
    </div>
  )
}

export default SignUp
